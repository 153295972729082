/* Main.css */

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1e1e1e;
}

.main-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.layout .sidebar {
  width: 20%;
  background-color: #2c2c2c;
}

.layout .chat-area {
  width: 80%;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  position: relative;
}

body {
  margin: 0;
  background-color: #1e1e1e;
  color: #ffffff;
}

.bot-error-msg {
  color: red;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .layout {
    height: calc(100vh - 51.5px);
  }

  .main-content .sidebar {
    position: fixed;
    width: 200px;
    left: -200px;
    padding: 0px;
  }

  .main-content.sidebar-visible .sidebar {
    width: 200px;
    left: 0px;
    padding: 5px;
  }

  .main-content .chat-area {
    width: 100vh;
  }

  .main-content.sidebar-visible .chat-area {
    width: 50%;
    margin-left: 200px;
  }

}