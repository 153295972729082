/* BottomToolsMenu.css */

.bottom-tools-menu {
  align-items: center;
  padding: 10px;
  background-color: #1a1a1a;
  /* Darker background for the entire container */
  width: 100%;
  flex-direction: column;
  position: relative;
  display: flex;
  bottom: 0;
  box-sizing: border-box;
}

.image-preview-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 10px;
}

.image-preview {
  position: relative;
  margin-right: 10px;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border: none;
  color: black;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50%;
}

/* New container for the input area with rounded corners */
.input-area-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  max-width: 1100px;
  /* Limit width on larger screens */
  margin: 0 auto;
  background-color: #2c2c2c;
  /* Grayish background for input area */
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.message-input {
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
  background-color: #2c2c2c;
  /* Match container background */
  border: none;
  color: #fff;
  border-radius: 8px;
  max-height: 250px;
  min-height: 24px;
  overflow: auto;
  resize: none;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;
  line-height: 1.4;
}

.buttons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tool-buttons {
  display: flex;
  gap: 8px;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.tool-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.tool-button.enabled {
  color: white;
}

.tool-button.enabled .button-text {
  font-weight: bold;
}

.tool-button.enabled svg {
  fill: white !important;
}

.tool-button.disabled {
  color: #666;
}

.tool-button.disabled svg {
  fill: #666 !important;
}

.tool-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button-text {
  margin-left: 4px;
  font-size: 0.9rem;
}

.send-button,
.attach-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover,
.attach-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Mic button styling */
.mic-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mic-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.mic-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Style for active recording state */
.mic-button.recording {
  background: rgba(255, 0, 0, 0.2);
}

.bottom-tools-menu .image-preview-container .placeholder {
  width: 100px;
  height: 100px;
  background-color: black;
}

.bottom-tools-menu-characters {
  background-color: #1e1e1e;
  width: 100%;
}

.pdfName {
  color: white;
  font-size: 0.6rem;
  position: absolute;
  top: 30px;
  left: 0;
  max-width: 100px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
}

/* Health options styles */
.floating-health-options-button {
  position: absolute;
  top: -20px;
  right: 10px;
  background-color: #555;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1000;
}

.health-options-section {
  background-color: #333;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  column-gap: 20px;
}

.health-switch {
  display: flex;
  align-items: center;
}

.health-switch label {
  font-size: 0.85rem;
  color: #fff;
  display: flex;
  align-items: center;
}

.health-switch input {
  margin-right: 10px;
}

.health-switch input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  background-color: #333;
  border-radius: 50%;
  border: 2px solid #444;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.health-switch input[type="checkbox"]:checked {
  background-color: rgb(225, 220, 220);
  border-color: #444;
}

.health-switch input[type="checkbox"]:hover {
  border-color: #1e1e1e;
}

/* MOBILE */
@media (max-width: 768px) {
  .bottom-tools-menu {
    padding: 3px;
    width: 100vw;
    box-sizing: border-box;
    position: relative;
    left: 0;
  }

  .input-area-container {
    width: calc(100vw - 16px);
    max-width: 100%;
    padding: 6px;
    margin: 0 auto 5px auto;
    box-sizing: border-box;
  }

  .button-text {
    display: none;
    /* Hide text on mobile to save space */
  }

  .tool-buttons {
    gap: 15px;
  }

  .action-buttons {
    gap: 15px;
  }

  .tool-button {
    padding: 2px;
    /* Minimal padding around buttons */
  }

  .send-button,
  .attach-button {
    padding: 2px;
    /* Minimal padding for send/attach buttons */
  }

  .message-input {
    padding: 6px;
    font-size: 0.95rem;
    margin-bottom: 3px;
    min-height: 20px;
    max-height: 80px;
    width: 100%;
    box-sizing: border-box;
  }

  .mic-button {
    padding: 2px;
    /* Minimal padding for mic button */
  }

  /* Make SVG icons smaller on mobile, similar to TopMenu */
  .tool-button svg,
  .send-button svg,
  .attach-button svg {
    width: 18px;
    height: 18px;
  }

  /* Adjust spacing in the buttons row */
  .buttons-row {
    justify-content: space-between;
    padding: 0 5px;
    width: 100%;
    /* Full width of container */
    box-sizing: border-box;
    /* Include padding in width calculation */
  }

  /* Health options adjustments */
  .health-options-section {
    column-gap: 5px;
    width: 100vw;
    /* Use viewport width */
    box-sizing: border-box;
    /* Include padding in width calculation */
  }

  .health-switch input[type="checkbox"] {
    margin-right: 3px;
    margin-left: 0px;
  }

  /* Image preview container adjustments */
  .image-preview-container {
    width: 100vw;
    box-sizing: border-box;
  }
}