/* Demo main.css */

/* Layout styles based on Main.css */
.demo-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #1e1e1e;
  color: #ffffff;
}

/* TopMenu modifications for vibes demo */
.demo-layout .menu-button {
  display: none !important;
  /* Hide hamburger menu button */
}

.demo-layout .session-buttons {
  display: none !important;
  /* Hide session buttons */
}

.demo-layout .dropdown-menu {
  display: none !important;
  /* Hide dropdown menu */
}

.demo-layout .menu-right {
  margin-left: auto;
  padding-left: 20px;
}

/* Hide tool buttons in BottomToolsMenu for vibes demo */
.demo-layout .tool-buttons {
  display: none !important;
}

.demo-layout .mic-button {
  display: none !important;
}

/* Adjust action buttons to be positioned on the right side */
.demo-layout .buttons-row {
  justify-content: flex-end;
}

.demo-layout .action-buttons {
  margin-left: auto;
}

/* Make the BottomToolsMenu take less vertical space */
.demo-bottom-tools-container {
  background-color: #1a1a1a;
}

.demo-layout .bottom-tools-menu {
  width: 50%;
  max-width: 800px;
  margin: 0 auto;
  padding: 12px;
}

/* Ensure input field is properly styled */
.demo-layout .message-input {
  background-color: #2c2c2c;
  color: #fff;
  border-radius: 8px;
}

/* Input container styles */
.demo-input-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #1a1a1a;
  width: 100%;
  box-sizing: border-box;
}

/* Input area container with rounded corners (based on BottomToolsMenu) */
.demo-input-area-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 800px;
  background-color: #2c2c2c;
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

/* Message input styles */
.demo-message-input {
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
  background-color: #2c2c2c;
  border: none;
  color: #fff;
  border-radius: 8px;
  max-height: 150px;
  min-height: 24px;
  overflow: auto;
  resize: none;
  margin-bottom: 10px;
  box-sizing: border-box;
  outline: none;
}

/* Buttons row styles */
.demo-buttons-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

/* Action buttons styles */
.demo-action-buttons {
  display: flex;
  gap: 8px;
}

/* Send button styles */
.demo-send-button {
  background-color: #4a4a4a;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.demo-send-button:hover {
  background-color: #5a5a5a;
}

.demo-send-button:disabled {
  background-color: #3a3a3a;
  color: #777;
  cursor: not-allowed;
}

/* Result container styles */
.demo-result-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #1e1e1e;
}

/* Messages container styles */
.demo-messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Empty state styles */
.demo-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #999;
  font-style: italic;
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 16px;
}

/* Error message styles */
.demo-error-msg {
  color: red;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  font-size: 1rem;
  margin-top: 10px;
}

/* Loading state styles */
.demo-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: #999;
  font-style: italic;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .demo-layout .bottom-tools-menu {
    width: 90%;
    padding: 8px;
  }

  .demo-message-input {
    padding: 6px;
    font-size: 0.95rem;
    margin-bottom: 6px;
  }

  .demo-send-button {
    padding: 6px 12px;
    font-size: 0.85rem;
  }
}