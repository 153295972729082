.map-container {
  height: 80vh;
  /* Map takes 80% of the viewport height */
  width: 100%;
}

.chart-container {
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
}