/* ChatMessage.css */
.chat-message {
  display: flex;
  align-items: flex-start;
  font-size: 1rem;
  margin-top: 12px;
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  position: relative;
}

.chat-message.user {
  background-color: #3a3a3a;
  text-align: left;
}

.chat-message.ai {
  background-color: #2c2c2c;
  text-align: left;
}


.chat-message .avatar {
  margin-right: 10px;
}

.chat-message .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-message .message-content {
  max-width: 95%;
  padding-bottom: 5px;
}

.chat-message p {
  margin: 0;
  line-height: 1.5;
  word-wrap: break-word;
}

.chat-message .image-container {
  display: flex;
  overflow-x: auto;
  margin-top: 10px;
}

.chat-message .image-container img {
  max-width: 256px;
  max-height: 256px;
  margin-right: 10px;
  cursor: pointer;
}

.chat-message audio {
  display: block;
  margin-top: 10px;
}

.img-chat-message-button {
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
  margin-top: 10px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.img-chat-message-button:hover {
  background-color: #2c2c2c;
  border-radius: 50%;
}

.file-placeholder-preview {
  position: relative;
  margin-right: 10px;
}

.file-placeholder {
  width: 75px;
  height: 100px;
  background-color: black;
  cursor: pointer;
}

.file-placeholder-preview .file-placeholder .pdfName {
  color: white;
  font-size: 0.6rem;
  position: absolute;
  top: 15px;
  left: 0;
  max-width: 75px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
}

.date-ai-model-name {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.6rem;
  color: white;
}

.date-ai-model-name .generated-info {
  margin-left: 5px;
}

a:-webkit-any-link {
  color: #bd93f9;
  /* Purple color for links */

}

/* copy button stuff */
.code-block-wrapper {
  position: relative;
  background-color: #1e1e1e;
  padding: 0rem;
  border-radius: 4px;
  margin: 0rem 0;
}

.code-block-wrapper pre {
  margin: 0;
  padding-right: 0rem;
}

.copy-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #444;
}

.chat-message pre code {
  display: block;
  overflow-x: auto;
  padding: 0em;
  background: #1e1e1e;
  color: #d4d4d4;
}

.user-message-pre {
  font-family: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  padding: 0;
}


pre[class*="language-"] {
  background-color: #1e1e1e;
}

/* there was problem with table display - it didn't fit into message properly */
.chat-message .message-content table {
  width: 100%;
  border-collapse: collapse;
}

.chat-message .message-content th,
.chat-message .message-content td {
  border: 1px solid #444;
  padding: 6px;
  text-align: left;
  word-break: break-word;
}

.chat-message .message-content th {
  background-color: #333;
}

/* end of table */

.android-audio-text {
  margin-top: 8px;
  font-size: 0.9em;
  color: #888;
}

/* MOBILE */
@media (max-width: 768px) {
  .file-placeholder {
    width: 60px;
    height: 80px;
  }

  .chat-message .image-container img {
    max-width: 192px;
    max-height: 192px;
  }

  .chat-message .avatar img {
    width: 30px;
    height: 30px;
  }

  .chat-message .message-content {
    max-width: 90%;
  }

  .chat-message .message-content table {
    font-size: 0.8rem;
  }

  .chat-message .message-content th,
  .chat-message .message-content td {
    padding: 4px;
  }

}