/* TopMenu.css */

.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c2c2c;
  border: 0px;
  color: #ffffff;
  padding: 10px 10px;
  z-index: 997;
}

.top-menu .menu-button,
.top-menu .new-chat-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.menu-right {
  display: flex;
  align-items: center;
}

.environment-selector,
.model-selector {
  margin-right: 30px;
}

.environment-selector select,
.model-selector select {
  padding: 10px;
  background-color: #2c2c2c;
  color: #fff;
  border: 1px solid #444;
  border-radius: 5px;
  outline: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.environment-selector select:hover,
.model-selector select:hover {
  background-color: #3a3a3a;
}

.health-section-top-menu-right .environment-selector,
.health-section-top-menu-right .model-selector {
  margin-right: 10px;

}

.dropdown-menu {
  position: absolute;
  top: 40px;
  /* Adjust based on your top menu height */
  left: 10px;
  background: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
}

.dropdown-item {
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #3a3a3a;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 999;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: 400px;
  padding: 10px;
  background: #2c2c2c;
  color: white;
  border: 1px solid #444;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.session-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1;
  z-index: 99;
}

.session-button-container {
  position: relative;
  z-index: 99;
}

.session-button {
  background-color: #444;
  border: none;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}

.session-button.active {
  background-color: #e8eaed;
  color: #1e1e1e;
  width: 45px;
  height: 45px;
  z-index: 99;
}

.session-button.add-session {
  background-color: #888;
}

.session-button:hover {
  background-color: #666;
}

.session-button img {
  height: 100%;
  border-radius: 50%;
}

.session-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.close-button {
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: black;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 768px) {

  .top-menu {
    padding: 10px 1px;
    max-width: 100%;
  }

  .environment-selector,
  .model-selector {
    margin-right: 5px;
  }

  .environment-selector select,
  .model-selector select {
    padding: 5px 2px;
    font-size: 12px;
  }

  .environment-selector select {
    width: 60px;
  }

  .model-selector select {
    width: 75px;
  }

  .session-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1;
  }

  .session-button {
    width: 25px;
    height: 25px;
  }

  .session-button.active {
    width: 25px;
    height: 25px;
  }

  .session-avatar {
    width: 25px;
    height: 25px;
  }

  .close-button {
    top: -5px;
    right: -5px;
  }

  .popup {
    max-width: 98%;
  }

  .dropdown-menu {
    top: 50px;
    border: 1px solid #444;
    padding: 5px;
    left: 5px;
  }

  .dropdown-item {
    padding: 5px;
    font-size: 0.9rem;
  }

}