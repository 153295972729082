/* CorrelationHeatmap.css */

.correlations-heatmap {
  margin-top: 120px;
}

.correlation-generate-button {
  background-color: #3a3a3a;
  color: #ffffff;
  border: none;
  padding: 6px;
  font-size: 0.85rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 30px;
}


.correlation-generate-button:hover {
  background-color: #444;
}

.correlations-chart-wrapper {
  width: 70%;
  margin: 0 auto 40px auto;
  height: 600px;
  /* This centers the chart on the page */
}

.correlations-threshold {
  width: 75%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.correlations-threshold-switches {
  margin-bottom: 40px;
}

/* MOBILE */

@media (max-width: 768px) {
  .correlations-chart-wrapper {
    width: 100%;
    margin: 0 auto;
    height: 500px;
    margin-bottom: 40px;
  }

  .correlations-heatmap h2 {
    font-size: 1.5rem;
  }

  .correlations-threshold {
    width: 100%;
  }
}