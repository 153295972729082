/* Sidebar.css */
.sidebar {
  padding: 10px;
  color: #fff;
  overflow-y: auto;
  height: calc(100vh - 60px);
}

.search-bar {
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 0px;
  margin-top: 15px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  color: #fff;
  border-radius: 5px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 5px;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
}

.sidebar ul li {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.sidebar ul li:hover {
  background-color: #3a3a3a;
}

.sidebar ul li.selected {
  background-color: #444;
  /* Change this to your desired selected background color */
}

.sidebar ul li.selected:hover {
  background-color: #555;
  /* Change this to your desired selected hover background color */
}

.sidebar li:hover {
  background-color: #3a3a3a;
}


.session-item {
  display: flex;
  align-items: flex-start;
  padding: 0px 0;
  transition: transform 0.2s ease-in-out;
}

.session-item:not(.hovered) .tag-icon.unset {
  display: none;
}

.session-item.hovered .tag-icon.set {
  transform: scale(1.5);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.session-details {
  flex-grow: 1;
}

.session-name {
  font-size: 0.9rem;
  margin-bottom: 2px;
}

.session-date {
  font-size: 0.7rem;
  color: #bbb;
  margin-bottom: 4px;
}

.session-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.tag-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s, opacity 0.2s;
}

.tag-icon:hover {
  transform: scale(1.4);
}

.tag-icon.set:hover {
  transform: scale(0.8);
}

.tag-icon svg {
  width: 14px;
  height: 14px;
  fill: #fff;
}

.context-menu {
  position: absolute;
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  z-index: 1000;
}

.context-menu-item {
  padding: 10px;
  cursor: pointer;
}

.context-menu-item:hover {
  background-color: #3a3a3a;
}

.rename-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  z-index: 1000;
  padding: 20px;
  width: 300px;
}

.rename-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rename-popup-content h3 {
  margin-bottom: 20px;
}

.rename-popup-content input {
  margin-bottom: 20px;
  padding: 5px;
  width: 100%;
  background-color: #444;
  color: #fff;
  border: 1px solid #555;
  border-radius: 5px;
}

.sidebar-popups .button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sidebar-popups button {
  padding: 5px 10px;
  background-color: #3a3a3a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  margin: 0 5px;
}

.sidebar-popups button:hover {
  background-color: #555;
}

/* session tags */
.session-tag-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  z-index: 1000;
  padding: 20px;
  width: 300px;
}

.session-tag-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.session-tag-list {
  width: 100%;
  margin-bottom: 30px;
}

.session-tag {
  display: inline-flex;
  align-items: center;
  background-color: #3a3a3a;
  color: #fff;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.session-tag:hover {
  background-color: #555;
}

.session-tag svg {
  margin-right: 5px;
  font-size: 14px;
}

/* end of session tags */

/* search filter */
.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-dropdown-icon {
  position: absolute;
  right: 2px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
}

.search-dropdown-icon svg {
  /* color */
  fill: #555;
}


.search-dropdown-menu {
  background-color: #2c2c2c;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 10px;
  margin-top: 5px;
}

.search-tag-icons {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.search-tag-icon {
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
}

.search-tag-icon.selected {
  opacity: 1;
}

.search-tag-icon svg {
  height: 18px;
  width: 18px;
}

.search-tag-icon.selected svg {
  transform: scale(1.1);
}

.search-dropdown-menu-button {
  width: 100%;
  padding: 5px;
  background-color: #3a3a3a;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.serach-date-picker-container {
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  margin-bottom: 15px;
  margin-left: 20px;
  width: 100%;
}

.custom-search-datepicker {
  background-color: #2c2c2c;
  color: #ffffff;
  border: 1px solid #444;
  justify-content: center;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}

.react-datepicker-wrapper {
  width: 90%;
}

/* end of search filter */

@media (max-width: 768px) {
  .search-bar {
    margin-top: 40px;
  }
}