/* ChatCharacters.css */

.personas-header {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding: 0.5rem 1rem;
  background-color: #333;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.personas-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* for smoother scrolling on iOS */
  margin-top: 0px;
  position: relative;
  z-index: 20;
}

.persona {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95px;
  margin: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  background-color: var(--main-bot-bgcolor);
  border-radius: 5px;
  padding: 4px;
}

.persona:hover {
  background-color: #2c2c2c;
  cursor: pointer;
}

.persona.active {
  background-color: #2c2c2c;
}

.persona-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.persona-name {
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 2em;
  word-wrap: break-word;
  line-height: 1.2;
  overflow-wrap: break-word;
  text-align: center;
  font-size: 0.9rem;
}

.option-item-characters {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.option-item-characters label {
  font-size: 0.9rem;
}

.option-item-characters input[type="checkbox"] {
  appearance: none;
  background-color: #333;
  border: 1px solid #555;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-left: 10px;

}

.option-item-characters input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}