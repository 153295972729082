/* ChatImageModal.css */

.image-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.image-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 900px;
  padding: 20px;
  /* Added padding for spacing */
  background-color: #333;
  /* Background color for better readability */
  border-radius: 10px;
  height: auto;
}

.modal-chart {
  width: 100%;
  height: 100%;
}

/* Add specific styles for charts */
.image-modal-content.modal-chart-container {
  height: 90vh;
  max-width: 80vw;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: white;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  max-height: 99vh;
  margin-bottom: 20px;
}

.modal-character-image {
  max-width: 50%;
  max-height: 60vh;
  margin-bottom: 20px;
}

.modal-character-name,
.modal-character-description {
  color: white;
  text-align: center;
  margin-top: 10px;
}

.modal-character-name {
  font-size: 20px;
  font-weight: bold;
}

.modal-character-description {
  font-size: 18px;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  user-select: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(47, 45, 45, 0.8);
}

/* MOBILE */
@media (max-width: 768px) {
  .modal-chart {
    height: 70vh;
  }

  .image-modal-content.modal-chart-container {
    height: 80vh;
    max-width: 99vw;
  }

  .image-modal-content {
    width: 99%;
    padding: 0px;
    min-height: 200px;
  }

  .close {
    top: 0px;
    right: 5px;
    font-size: 25px;
  }

  .prev,
  .next {
    width: auto;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: -22px;
    font-size: 15px;
  }


}