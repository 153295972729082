/* ProgressIndicator.css */
.progress-indicator {
 display: flex;
 align-items: center;
 background-color: #2c2c2c;
 padding: 10px;
 border-radius: 10px;
 margin: 10px 0;
}

.progress-bar {
 width: 15px;
 height: 10px;
 background-color: #444;
 border-radius: 5px;
 overflow: hidden;
 margin-right: 10px;
}

.progress {
 width: 0;
 height: 100%;
 background-color: #76c7c0;
 animation: loading 2s infinite;
}

@keyframes loading {
 0% {
  width: 0;
 }

 50% {
  width: 100%;
 }

 100% {
  width: 0;
 }
}

.progress-message {
 color: #ffffff;
}